import React, { useState } from "react";
import request from "request";
import appConfig from "../../config/app-config.json";
import DataType from "./DataType";
import Loading from "./Loading";
import ResultTable from "./ResultTable";

const SearchForm = ({ idToken, defaultOption, onRedirect }) => {
  const [timeInterval, setTimeInterval] = useState(7);
  const [dataType, setDataType] = useState(defaultOption);
  const [nameFilter, setNameFilter] = useState("");

  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState();

  const handleSubmit = () => {
    const options = {
      url:
        `${appConfig.dataAPIUri}/data/search?DayInterval=` +
        timeInterval +
        `&SearchCategory=` +
        dataType +
        `&NameFilter=` +
        nameFilter,
      headers: {
        Authorization: `${idToken}`,
      },
    };
    setLoading(true);
    request.get(options, (err, resp, body) => {
      setLoading(false);
      if (err) {
        setError(JSON.stringify(err));
      } else if (resp.statusCode === 403) {
        onRedirect();
      } else if (resp.statusCode >= 400) {
        setError(body);
        if (body.includes("The incoming token has expired")) {
          setError("Session expired, Please Sign out and Sign back in");
          onRedirect();
        }
      } else {
        setData(JSON.parse(body));
      }
    });
  };

  return (
    <div>
      <form>
        <table>
          <tbody>
            <tr>
              <td>
                <table>
                  <tbody className="table-thick">
                    <tr>
                      <td align="left">
                        <font size="3">Interval</font>
                      </td>
                      <td>
                        <br />
                      </td>
                      <td>
                        <br />
                      </td>
                      <td>
                        <br />
                      </td>

                      <td align="center">
                        <select
                          className="select"
                          id="TimeInterval"
                          onChange={(e) => setTimeInterval(e.target.value)}
                        >
                          <option value="7" defaultChecked={true}>
                            7 Days
                          </option>
                          <option value="30">30 Days</option>
                          <option value="60">60 Days</option>
                          {dataType !== DataType.NoticeToProceed ? (
                            <>
                              <option value="90">90 Days</option>
                              <option value="365">365 Days</option>
                            </>
                          ) : (
                            <></>
                          )}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <br />
                      </td>
                    </tr>

                    <tr>
                      <td align="left">
                        <font size="3">Searct Type</font>
                      </td>
                      <td>
                        <br />
                      </td>
                      <td>
                        <br />
                      </td>
                      <td>
                        <br />
                      </td>

                      <td align="left">
                        <select
                          className="select"
                          id="DataType"
                          defaultValue={defaultOption}
                          onChange={(e) => {
                            if (
                              e.target.value === DataType.NoticeToProceed &&
                              timeInterval > 60
                            )
                              setTimeInterval(7);
                            setDataType(e.target.value);
                          }}
                        >
                          <option value={DataType.NoticeToProceed}>
                            Notice to Proceed
                          </option>
                          <option value={DataType.Sales}>Funded Data</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <br />
                      </td>
                    </tr>

                    <tr>
                      <td align="left">
                        <font size="3">Name Search</font>
                      </td>
                      <td>
                        <br />
                      </td>
                      <td>
                        <br />
                      </td>
                      <td>
                        <br />
                      </td>

                      <td align="left">
                        <input
                          className="input"
                          id="NameFilter"
                          onChange={(e) => setNameFilter(e.target.value)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td align="right" colSpan="20">
                {" "}
              </td>

              <td align="right">
                <button type="button" className="search" onClick={handleSubmit}>
                  Search
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <p></p>
      <div className="component-border">
        {loading ? (
          <div className="sweet-loading">
            <Loading loading={loading} />
          </div>
        ) : (
          <ResultTable data={data} error={error} />
        )}
      </div>
    </div>
  );
};

export default SearchForm;
