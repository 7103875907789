import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import request from "request";
import cognitoUtils from "../../lib/cognitoUtils";
import appConfig from "../../config/app-config.json";
import SearchForm from "./SearchForm";
import Footer from "./Footer";
import Header from "./Header";
import "./Home.css";
import Loading from "./Loading";
import Messages from "./Messages";

const mapStateToProps = (state) => {
  return { session: state.session };
};

const Home = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [option, setOption] = useState();
  const [nextClicked, setNextClicked] = useState();

  useEffect(() => {
    const options = {
      url: `${appConfig.dataAPIUri}/messages`,
      headers: {
        Authorization: `${props.session.credentials.idToken}`,
      },
    };
    setLoading(true);
    request.get(options, (err, resp, body) => {
      setLoading(false);
      if (err) {
        setError(JSON.stringify(err));
      } else if (resp.statusCode === 403) {
        handleRedirect();
      } else if (resp.statusCode >= 400) {
        setError(body);
        if (body.includes("The incoming token has expired")) {
          setError("Session expired, Please Sign out and Sign back in");
          handleRedirect();
        }
      } else {
        setData(JSON.parse(body));
      }
    });
  }, [props?.session?.credentials?.idToken]);

  useEffect(() => {
    if (!props.session.isLoggedIn) {
      handleRedirect();
    }
  }, [props.session.isLoggedIn]);

  const handleRedirect = () => {
    cognitoUtils.signOutCognitoSession();
    setRedirect(true);
  };

  const handleSignOutClick = (e) => {
    e.preventDefault();
    setRedirect(true);
  };

  const renderContent = () => {
    if (nextClicked)
      return (
        <SearchForm
          idToken={props.session.credentials.idToken}
          defaultOption={option}
          onRedirect={handleRedirect}
        />
      );
    else if (loading || !data) return <Loading loading={loading} />;
    else
      return (
        <Messages
          messages={data}
          onNext={(o) => {
            setOption(o);
            setNextClicked(true);
          }}
        />
      );
  };

  if (redirect || !props.session.isLoggedIn) {
    window.location = "/login";
    return <></>;
  } else
    return (
      <div className="Home">
        <Header
          isLoggedIn={props.session.isLoggedIn}
          email={props.session.user.email}
          userName={props.session.user.userName}
          onClick={handleSignOutClick}
        />
        <div className="container">
          <div>
            <div className="page-border">{renderContent()}</div>
          </div>
        </div>
        <div className="page-border"></div>
        <Footer />
      </div>
    );
};

export default connect(mapStateToProps)(Home);
