import React from "react";
const Footer = () => (
  <div className="fixed-footer">
    <div className="container">Copyright © 2020 Turns Financing </div>
    <div>
      For question or concerns about your Turns Portal access or data please
      email <i>newcontractor@turnsfinancing.com</i> include your Login and a contact
      number.
    </div>
  </div>
);

export default Footer;
