import React from "react";
import { Router, Route } from "react-router-dom";
import Callback from "./routes/Callback/Callback";
import Home from "./routes/Home/Home";
import appConfig from "./config/app-config.json";

import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const App = () => (
  <Router history={history}>
    <Route exact path="/" component={Home} />
    <Route exact path="/index.html" component={Home} />
    <Route exact path="/callback" component={Callback} />
    <Route
      path="/login"
      component={() => {
        let redirectUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`;
        window.location.href = redirectUri;
        return null;
      }}
    />
  </Router>
);

export default App;
