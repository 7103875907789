import React from "react";
import logo from "../../images/logo.png";
import cognitoUtils from "../../lib/cognitoUtils";

const Header = ({ isLoggedIn, username, email, onClick }) => {
  return (
    <header className="Home-header">
      <table className="header-table">
        <tbody>
          <tr>
            <td>
              <img src={logo} alt="logo" />
            </td>
            {isLoggedIn ? (
              <td>
                <a className="link" href="#" onClick={onClick}>
                  {username} ({email}) | Sign Out
                  <br />
                </a>
              </td>
            ) : (
              <td>
                <a className="link" href={cognitoUtils.getCognitoSignInUri()}>
                  Sign in
                </a>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </header>
  );
};

export default Header;
