import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index: 1000;
`;

const Loading = ({ loading }) => (
  <ClipLoader
    css={override}
    sizeUnit={"px"}
    size={100}
    color={"#123abc"}
    loading={loading}
  />
);

export default Loading;
