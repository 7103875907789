import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { initSessionFromCallbackURI } from "../../actions/session";

const mapStateToProps = (state) => {
  return { session: state.session };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initSessionFromCallbackURI: (href) =>
      dispatch(initSessionFromCallbackURI(href)),
  };
};

/**
  Callback route used after a successful Cognito sign-in. The window URL will contain the code we can
  use to get a Cognito session, which includes JWT tokens etc
 */
const Callback = ({ location, session, initSessionFromCallbackURI }) => {
  // If a Cognito auth code is in the URL (could be a hash or query component), init the new session
  useEffect(() => {
    if (location.hash || location.search) {
      initSessionFromCallbackURI(window.location.href);
    }
  }, [location, session]);

  // If there's no auth code in the URL or we're now logged into, redirect to the root page
  if ((!location.hash && !location.search) || session.isLoggedIn) {
    return <Redirect to="/" />;
  }

  return <div />;
};

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
