import React, { useState } from "react";
import DataType from "./DataType";

const Messages = ({ messages, onNext }) => {
  const [option, setOption] = useState("APP");

  const renderMessage = (m) => (
    <li dangerouslySetInnerHTML={{ __html: m }}></li>
  );

  return (
    <>
      {messages && messages.length > 0 ? (
        <div className="messages">
          <ul>{messages.map((m) => renderMessage(m.msg))}</ul>
        </div>
      ) : (
        <></>
      )}
      <div
        className={`options ${messages && messages.length > 0 ? "" : "center"}`}
      >
        <input
          type="radio"
          name="funding"
          value={DataType.Sales}
          id="funding"
          checked={option === DataType.Sales}
          onClick={() => setOption(DataType.Sales)}
        />
        <label htmlFor="funding">Funding</label>
        <input
          type="radio"
          name="noticeToProceed"
          value={DataType.NoticeToProceed}
          id="noticeToProceed"
          checked={option === DataType.NoticeToProceed}
          onClick={() => setOption(DataType.NoticeToProceed)}
        />
        <label htmlFor="noticeToProceed">Notice to Proceed</label>

        <button
          type="button"
          className="search"
          onClick={() => {
            onNext(option);
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Messages;
