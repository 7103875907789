import React from "react";
import { CSVLink } from "react-csv";

const ResultTable = ({ data, error }) => {
  const safePropsGet = () => {
    return data || [];
  };
  const renderTableHeader = () => {
    let header = Object.keys(safePropsGet()[0] || []);
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };
  const renderTableData = () => {
    return safePropsGet().map((row, index) => {
      let cols = Object.keys(row).map((it) => {
        return <td>{row[it]}</td>;
      });
      return <tr key={index}>{cols}</tr>;
    });
  };

  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          {data ? (
            data.length > 0 ? (
              <tr className="th-no-border">
                <th className="th-no-border">
                  <div className="th-no-border">
                    <CSVLink
                      data={data || []}
                      filename={"search-data.csv"}
                      className="download"
                      target="_blank"
                    >
                      Download
                    </CSVLink>
                  </div>
                </th>
              </tr>
            ) : (
              <p className="p-msg">No Matching Results</p>
            )
          ) : error ? (
            <p className="p-msg">{error}</p>
          ) : (
            <p></p>
          )}

          <tr>{renderTableHeader()}</tr>
        </thead>
        <tbody>{renderTableData()}</tbody>
      </table>
    </div>
  );
};

export default ResultTable;
